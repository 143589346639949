import loadable from '@loadable/component'
const Header = loadable(() => import('../components/Header'))
const Logo = loadable(() => import('../components/Logo'))

export default function Error() {
  return (
    <>
      <Header />
      <div className="flex min-h-[calc(100vh-122.84px)] w-screen flex-col items-center justify-center space-y-4 bg-dark py-2 font-heading md:min-h-[calc(100vh-138.84px)] lg:min-h-[calc(100vh-68px)]">
        <Logo />
        <div className="flex flex-row items-center space-x-6">
          <h1 className="text-2xl font-bold text-white">404</h1>
          <span className="text-2xl text-white-dark"> | </span>
          <div>
            <h2 className="text-sm font-thin text-white">
              This page could not be found
            </h2>
          </div>
        </div>

        <p className="font-base text-sm font-thin text-white">
          We're great at fixing roofs, but it looks like this page needs some
          repairs too. Sorry for the inconvenience!
        </p>
      </div>
    </>
  )
}
